import React, { Fragment, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Switcher } from '@dmm/lib-react-ui-components';

import FilterCity from '../FilterCity';
import FilterZip from '../FilterZip';
import SearchUserLocation from '../SearchUserLocation';
import { DEFAULT_REFINE_SEARCH } from '../../../constants/boats';
import { ENGINES_ID, DEFAULT_ENGINE_REFINE_SEARCH } from '../../../constants/Engines';
import FilterState from '../FilterState';

const switcherOptions = [
  {
    label: 'Zip',
    name: 'zip',
    value: 0
  },
  {
    label: 'City / State',
    name: 'city_or_state',
    value: 1
  }
];

const FilterLocation = (props) =>  {
  const [locationOptions] = useState([
    { value: 'exact', label: 'Zip Only' },
    { value: '10', label: '10 miles' },
    { value: '25', label: '25 miles' },
    { value: '50', label: '50 miles' },
    { value: '75', label: '75 miles' },
    { value: '100', label: '100 miles' },
    { value: '200', label: '200 miles' },
    { value: '300', label: '300 miles' },
    { value: '500', label: '500 miles' },
    { value: '1000', label: '1000 miles' }
  ]);
  const [zip, setZip] = useState('');
  const [state, setState] = useState('');
  const [radius, setRadius] = useState('');
  const [inputZip, setInputZip] = useState('');
  const [switcherIdx, setSwitcherIdx] = useState(props.switcherIdx || null);


  const handleTracking = (type, value) => {
    if (props.tracking) {
      if (value) {
        props.tracking.facetAdded({[type]: value});
      } else {
        props.tracking.facetRemoved(`removed ${type}`);
      }
    }
  };

  const onMultidataChange = (type, value, debounce) => {
    const defaultCity = DEFAULT_REFINE_SEARCH.city;
    const defaultZip = DEFAULT_REFINE_SEARCH.zip;
    const defaultState = DEFAULT_REFINE_SEARCH.state;
    let locationChanged = {city: defaultCity, zip: defaultZip, state: defaultState};
    if (type === 'state') {
      locationChanged.state = value;
      locationChanged.radius = props.searchPage === ENGINES_ID ? DEFAULT_ENGINE_REFINE_SEARCH.radius : DEFAULT_REFINE_SEARCH.radius;
    } else if (type === 'radius') {
      locationChanged.state = props.state || defaultState;
      locationChanged.city = props.selectedCities?.length === 1 ? props.selectedCities : defaultCity;
      locationChanged.zip = props.zip || defaultZip;
      locationChanged.radius = value;
    } else {
      locationChanged = {...locationChanged, [type]: value};
    }

    props.handleMultiDataChange(locationChanged, debounce);
  };

  const handleDataChange = (type, value, debounce) => {
    handleTracking(type, value);
    onMultidataChange(type, value, debounce);
  };

  const updateMultiInput = () => {
    console.log('executing updateMultiInput, props', props);
    if (props.zip !== undefined) {
      setZip(props.zip);
    }
    if (props.radius) {
      setRadius(props.radius);
    }
    if (props.state !== undefined) {
      setState(props.state);
    }
  };

  useEffect(() => {
    updateMultiInput();
    setInputZip(props.zip);
  }, []);

  useEffect(() => {
    updateMultiInput();
  }, [props]);

  const onChangeDistance = (value) => {
    handleDataChange('radius', value.target.value);
  };

  const onChangeZipCode = (value) => {
    setInputZip(value.target.value);
    if (value.target.value.length === 5 || value.target.value.length === 0) {
      handleDataChange('zip', value.target.value, true);
    }
  };

  const onClickState = (value) => {
    handleDataChange('state', value);
  };

  const onUserLocationClick = (userZipCode, userRadiusMiles) => {

    const city = DEFAULT_REFINE_SEARCH.city;
    const state = DEFAULT_REFINE_SEARCH.state;
    let locationChanged = {city, state};

    setInputZip(userZipCode);
    handleTracking('zip', userZipCode);
    handleTracking('radius', userRadiusMiles);

    locationChanged.state = state;
    locationChanged.city = city;
    locationChanged.zip = userZipCode;
    locationChanged.radius = userRadiusMiles;

    props.handleMultiDataChange(locationChanged, true);
  };

  const onSwitcherChange = (_, value) => {
    setSwitcherIdx(value);
  };

  let { state: stateFromProps = '', country, states, position, params = {}, searchPage = '', hideUserLocation } = props;
  let activeIndex = switcherIdx || params.state && !params.zip ? 1 : 0;

  return (
    <div className="location-filter-wrapper">
      <Switcher
        defaultIndex={activeIndex}
        fullWidth
        options={switcherOptions}
        onSwitch={onSwitcherChange}
      />

      {activeIndex === 0 && (
        <div className={classnames('search-filter location', {loading: props.loading})}>
          <div className="search-filter-group">
            <select
              className="filter-select"
              data-e2e="distance-radius"
              value={radius}
              onChange={onChangeDistance}
              title="Distance radius"
            >
              {locationOptions.map((obj) =>
                <option key={obj.value} value={obj.value}>{obj.label}</option>
              )}
            </select>
            <span>from</span>
            <input
              type="number"
              data-e2e="zip-code-input"
              className="zip"
              value={inputZip ? inputZip : zip}
              placeholder="Zip"
              onChange={onChangeZipCode}
              title="Zip code"
            />
          </div>
          {!hideUserLocation && <SearchUserLocation onUserLocationClick={onUserLocationClick}/>}
        </div>
      )}

      {activeIndex === 1 && (
        <Fragment>
          <FilterState
            state={stateFromProps}
            country={country}
            states={states}
            position={position}
            params={params}
            loading={props.loading}
            selectedState={state}
            onClickState={onClickState}
            searchPage={searchPage}
          />
          <FilterCity
            loading={props.loading}
            position={props.position}
            selectedState={props.selectedState}
            selectedCities={props.selectedCities}
            stateCity={props.stateCity}
            handleMultiDataChange={props.handleMultiDataChange}
            params={params}
            tracking={props.tracking}
            searchPage={searchPage}
          />
          <FilterZip
            loading={props.loading}
            position={props.position}
            selectedCities={props.selectedCities}
            cityZip={props.cityZip}
            zip={inputZip}
            handleMultiDataChange={props.handleMultiDataChange}
            params={params}
            tracking={props.tracking}
            searchPage={searchPage}
          />
        </Fragment>
      )}
    </div>
  );
};

export default FilterLocation;
